var User = (function() {
    var getName = function() {
      return localStorage.getItem('full_name')   // Or pull this from cookie/localStorage
    };
  
    var setName = function(name) {
      if(name !== "")
        localStorage.setItem('full_name', name)     
      else
        localStorage.clear()
      // Also set this in cookie/localStorage
    };
  
    return {
      getName: getName,
      setName: setName
    }
  
  })();
  
  export default User;