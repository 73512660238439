/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      saldo: {
        paas: 0,
        webnic: 0,
        resellercamp: 0,
        resellerclub: 0
      },
      datatiket: null,
      totalTiket: 0,
      ticket: null,
      domain: null
    };
  }

  updaterData = () => {
    this.updateSaldo();
    this.updateTicket();
    this.updateDataTiket();
    this.updateDataDomain();
  }

  updateDataDomain = () => {
    fetch('https://monitor-api.cloudhost.id/domain')
    .then(res => res.json())
    .then((data) => {
      this.setState({...this.state,
        domain: data
      })
    })
  }

  updateDataTiket = () => {
    let total = 0
    fetch('https://monitor-api.cloudhost.id/counttiket')
    .then(res => res.json())
    .then((data) => {
      Object.keys(data).forEach((key, id) => {
        Object.keys(data[key]).forEach((k, i) => {
          total += data[key][k]
        })
      })
      this.setState({...this.state,
        datatiket: data,
        totalTiket: total
      })
    })
  }

  updateSaldo = () => {
    // saldo rest local
    fetch('https://monitor-api.cloudhost.id/saldo')
    .then(res => res.json())
    .then((data) => {
      this.setState({...this.state,
        saldo: data
      })
    })
    // end saldo rest local
  }

  updateTicket = () => {
    fetch('https://monitor-api.cloudhost.id/ticket')
    .then(res => res.json())
    .then((data) => {
      this.setState({...this.state,
        ticket: data
      })
    })
  }

  setBgChartData = name => {
    this.setState({ ...this.state,
      bigChartData: name
    });
  };

  componentDidMount() {
    this.updaterData();
    this.interval = setInterval(() => this.updaterData(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let tableBody, ticketBody, dataTicketBody, filterBody;

    if(this.state.domain){
      filterBody = this.state.domain.map((val, i) => {
        return <tr>
          <td><a target="_blank" rel="noopener noreferrer" href={"https://my.idcloudhost.com/bismillahbisa/clientsdomains.php?userid="+val.userid+"&id="+val.domainid}>{val.domain}</a></td>
          <td>{val.registrationdate}</td>
        </tr>
      })
    }else{
      const coba = [1,2,3,4,5]
      filterBody = coba.map((val, i) => {
      return <tr><td><Skeleton width={421}/></td><td><Skeleton wdith={224}/></td></tr>
      })
    }

    if(this.state.datatiket){
      const technical = Object.keys(this.state.datatiket["1"]).map((key, i) => {
        let lvl = {'inverse': true,'color': 'danger'}
        if(this.state.datatiket["1"][key] < 5) lvl.color="warning"
        if(this.state.datatiket["1"][key] < 3) lvl.color="success"
        return <Row><Col md="12"><Card {...lvl} className="text-center"><CardBody style={{color:'black'}}><h3 style={{marginBottom: "0px", color:'black'}}>{this.state.datatiket["1"][key]}</h3>{key}</CardBody></Card></Col></Row>
      })
      const billing = Object.keys(this.state.datatiket["2"]).map((key, i) => {
        let lvl = {'inverse': true,'color': 'danger'}
        if(this.state.datatiket["2"][key] < 5) lvl.color="warning"
        if(this.state.datatiket["2"][key] < 3) lvl.color="success"
        return <Row><Col md="12"><Card {...lvl} className="text-center"><CardBody style={{color:'black'}}><h3 style={{marginBottom: "0px", color:'black'}}>{this.state.datatiket["2"][key]}</h3>{key}</CardBody></Card></Col></Row>
      })
      const sales = Object.keys(this.state.datatiket["4"]).map((key, i) => {
        let lvl = {'inverse': true,'color': 'danger'}
        if(this.state.datatiket["4"][key] < 5) lvl.color="warning"
        if(this.state.datatiket["4"][key] < 3) lvl.color="success"
        return <Row><Col md="12"><Card {...lvl} className="text-center"><CardBody style={{color:'black'}}><h3 style={{marginBottom: "0px", color:'black'}}>{this.state.datatiket["4"][key]}</h3>{key}</CardBody></Card></Col></Row>
      })
      dataTicketBody = <Row>
          <Col md="4">
            <Card><CardHeader><CardTitle className="text-center">Technical</CardTitle></CardHeader><CardBody>{technical}</CardBody></Card>
          </Col>
          <Col md="4">
            <Card><CardHeader><CardTitle className="text-center">Billing</CardTitle></CardHeader><CardBody>{billing}</CardBody></Card>
          </Col>
          <Col md="4">
            <Card><CardHeader><CardTitle className="text-center">Sales</CardTitle></CardHeader><CardBody>{sales}</CardBody></Card>
          </Col>
        </Row>
    }else{
      dataTicketBody = <Row>
          <Col md="4">
            <Card><CardHeader><CardTitle className="text-center">Technical</CardTitle></CardHeader><CardBody><Skeleton count={3} height={83}/></CardBody></Card>
          </Col>
          <Col md="4">
            <Card><CardHeader><CardTitle className="text-center">Billing</CardTitle></CardHeader><CardBody><Skeleton count={3} height={83}/></CardBody></Card>
          </Col>
          <Col md="4">
            <Card><CardHeader><CardTitle className="text-center">Sales</CardTitle></CardHeader><CardBody><Skeleton count={3} height={83}/></CardBody></Card>
          </Col>
        </Row>
    }

    ticketBody = (this.state.ticket) ? <Bar data={{
      labels: Object.keys(this.state.ticket), 
      datasets: [{
        label: '',
        backgroundColor: Object.values(this.state.ticket).map((val, i) => {
          return (val > 1) ? 'rgba(215, 44, 44, 0.6)' : 'rgba(118, 187, 0, 0.6)'
        }),
        data: Object.values(this.state.ticket)
      }]
    }}/> : <Skeleton height={260}/>;

    if(this.state.saldo.paas){
      tableBody = <tbody>
        <tr>
          <td>Paas</td>
          <td><span style={(this.state.saldo.paas <= 1000000) ? {color:'red', fontWeight:'bold'} : {}}>{new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
}).format(this.state.saldo.paas)}</span></td>
        </tr>
        <tr>
          <td>ResellerCamp</td>
          <td><span style={(this.state.saldo.resellercamp <= 100) ? {color:'red', fontWeight:'bold'} : {}}>{new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format(this.state.saldo.resellercamp)}</span></td>
        </tr>
        <tr>
          <td>GoGet SSL</td>
          <td><span style={(this.state.saldo.gogetssl <= 100) ? {color:'red', fontWeight:'bold'} : {}}>{new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format(this.state.saldo.gogetssl)}</span></td>
        </tr>
        <tr>
          <td>ResellerClub</td>
          <td><span style={(this.state.saldo.paas <= 1000000) ? {color:'red', fontWeight:'bold'} : {}}>{this.state.saldo.resellerclub}</span></td>
        </tr>
        <tr>
          <td>Webnic</td>
          <td><span style={(this.state.saldo.webnic <= 100) ? {color:'red', fontWeight:'bold'} : {}}>{new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format(this.state.saldo.webnic)}</span></td>
        </tr>
      </tbody>
    }else{
      tableBody = <tbody>
        <tr colspan="2">
          <td><Skeleton/></td>
        </tr>
        <tr colspan="2">
          <td><Skeleton/></td>
        </tr>
        <tr colspan="2">
          <td><Skeleton/></td>
        </tr>
        <tr colspan="2">
          <td><Skeleton/></td>
        </tr>
      </tbody>
    }
    return (
      <>
        <div className="content">

        <SkeletonTheme color="#202020" highlightColor="#444">
          <Row>
            <Col lg="6" md="12">
              <Row>
                <Col lg="12" md="12">
                  <Card>
                      <CardHeader>
                        <Row>
                          <Col className="text-left" sm="6">
                            <CardTitle tag="h4">Ticket Summary</CardTitle>
                          </Col>
                          <Col className="text-right" sm="6">
                            {(this.state.totalTiket) ? "Total : "+this.state.totalTiket : <Skeleton height={24} width={60}/>}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {dataTicketBody}
                      </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12">
                    <Card>
                      <CardHeader tag="h4">Last 5 Filtered Domain</CardHeader>
                      <CardBody>
                        <Table className="tablesorter" responsive>
                          <thead>
                            <tr>
                              <th>Domain</th>
                              <th>Registration Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterBody}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="12">
              <Row>
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Balance</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter" responsive>
                        {tableBody}
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12">
                  <Card>
                      <CardHeader>
                        <Row>
                          <Col className="text-left" sm="6">
                        <CardTitle tag="h4">Ticket Avg Response Time</CardTitle>
                          </Col>
                          <Col className="text-right" sm="6">
                            {(this.state.ticket) ? "Today : "+Number(Object.values(this.state.ticket)[Object.values(this.state.ticket).length - 1]).toFixed(2)+" Jam" : <Skeleton height={24} width={60}/>}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {ticketBody}
                      </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </SkeletonTheme>
        </div>
      </>
    );
  }
}

export default Dashboard;
