import React from "react";
import User from "User";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col, Button, Form, FormGroup, Label, Input, FormText, Alert
} from "reactstrap";

class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          username: '',
          password: '',
          visible: false,
          loading: false,
          msg: ""
      }
    }

    loginClick = (event) => {
        this.setState({...this.state,
            loading: true
        })
        if(this.state.username != '' && this.state.password != ''){
            fetch("https://monitor-api.cloudhost.id/login", {
            // fetch("http://localhost:8000/login", {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    "Content-Type" : "application/json"
                }),
                body: JSON.stringify({
                    username: this.state.username,
                    password: this.state.password
                })
            })
            .then(res => res.json())
            .then((data) => {
                if(data){
                    console.log(User.getName())
                    User.setName("admin")
                    console.log(User.getName())
                    document.location.href = 'https://monitor.cloudhost.id/'
                }else{
                    this.setState({...this.state,
                        visible: true,
                        msg: "Username dan password salah",
                        loading: false
                    })
                    setInterval(() => {
                        this.setState({...this.state,
                            visible: false
                        })
                    }, 3000)
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({...this.state,
                    visible: true,
                    msg: "Terjadi kesalahan request",
                    loading: false
                })
                setInterval(() => {
                    this.setState({...this.state,
                        visible: false
                    })
                }, 3000)
            })
        }else{
            this.setState({...this.state,
                visible: true,
                msg: "Harap isi username dan password",
                loading: false
            })
            setInterval(() => {
                this.setState({...this.state,
                    visible: false
                })
            }, 3000)
        }
        event.preventDefault();
    }
    
    handleChange = (event) => {
        const id = event.target.id
        const val = event.target.value
        if(id === "username")
            this.setState({...this.state,
                username: val
            })
        else
            this.setState({...this.state,
                password: val
            })
    }

    render() {
      return (
          <div>
              <Card style={{maxWidth: 500,margin:"100px auto"}}>
                  <CardHeader>
                      <CardTitle className="text-center" tag="h4">
                          <img alt="..." src={require("assets/img/logophoto.png")} height={50}/>
                      </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.loginClick.bind(this)}>
                        <Alert color="danger" isOpen={this.state.visible} fade={true}>
                            {this.state.msg}
                        </Alert>
                        <FormGroup row>
                            <Label for="username" sm={4}>Username</Label>
                            <Col sm={8}>
                            <Input type="text" name="username" id="username" placeholder="Username" onKeyUp={this.handleChange.bind(this)}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="password" sm={4}>Password</Label>
                            <Col sm={8}>
                            <Input type="password" name="password" id="password" placeholder="password" onKeyUp={this.handleChange.bind(this)}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>         
                            <Col className="text-right">
                                <Button type="submit" onClick={this.loginClick.bind(this)}>
                                    <span className={(this.state.loading) ? "d-none" : ""}>Login</span>
                                    <span className={(!this.state.loading) ? "d-none" : ""}>Loading...</span>
                                </Button>
                            </Col>
                        </FormGroup>
                    </Form>
                  </CardBody>
              </Card>
          </div>
      );
    }
}
export default Login;
